@import "minima/skins/classic";
@import "minima/initialize";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700|Ubuntu:700");

html {
    background-color: #DDD;
}

.u-email {
    unicode-bidi:bidi-override;
    direction: rtl;
}

.wrapper {
    background-color: #FFFFDD;
    font-family: 'Open Sans', sans-serif; /*usa la cosa de google */
    border-top: 0.5px solid #e8e8e8;
    border-bottom: 0.5px solid #e8e8e8;
}

.page-content, .site-footer {
    padding: 0;
}

.page-content > .wrapper, .site-footer > .wrapper {
    padding: 30px;
}

.site-header, .site-footer {
  border-top: 0;
  border-bottom: 0;
}

/* navigation */

.PageNavigation {
    font-size: 14px;
    display: block;
    width: auto;
    overflow: hidden;
  }

  .PageNavigation a {
    display: block;
    width: 50%;
    float: left;
    margin: 1em 0;
  }

  .PageNavigation .next {
    text-align: right;
  }

  .rss-subscribe {
    display: none;
  }
